import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path1/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "get-started"
    }}>{`Get Started`}</h1>
    <h2 {...{
      "id": "the-hoppin-design-system-is-based-on"
    }}>{`The Hoppin Design System is based on:`}</h2>
    <ul>
      <li parentName="ul">{`Styled-System for token-based theming`}</li>
      <li parentName="ul">{`Styled-Components for CSS-in-JS (see below)`}</li>
    </ul>
    <h2 {...{
      "id": "todo--discussion"
    }}>{`TODO / Discussion`}</h2>
    <ul>
      <li parentName="ul">{`styled-components forward all props to the rendered HTML (using a simple whitelist) resulting in lots of warnings in the console and bad HTML.`}
        <ul parentName="li">
          <li parentName="ul">{`should we use emotion instead?`}</li>
          <li parentName="ul">{`same API when using emotion/styled `}<a parentName="li" {...{
              "href": "https://emotion.sh/docs/styled"
            }}>{`https://emotion.sh/docs/styled`}</a></li>
          <li parentName="ul">{`extra features with babel plugin: `}<a parentName="li" {...{
              "href": "https://github.com/emotion-js/emotion/tree/master/packages/babel-plugin-emotion"
            }}>{`https://github.com/emotion-js/emotion/tree/master/packages/babel-plugin-emotion`}</a>
            <ul parentName="li">
              <li parentName="ul">{`harder to use with create-react-app, as you need to change babel config.`}</li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">{`do we want to use something like `}<a parentName="li" {...{
          "href": "https://reakit.io/"
        }}>{`Reakit`}</a>{` for accessibility?`}</li>
    </ul>
    <h2 {...{
      "id": "philosophy"
    }}>{`Philosophy`}</h2>
    <p><em parentName="p">{`A design system is a collection of reusable components, guided by clear standards, that can be assembled together to build any number of applications.`}</em></p>
    <p>{`Goal to be as useful as `}<a parentName="p" {...{
        "href": "https://polaris.shopify.com/components/actions/button#navigation"
      }}>{`Shopify Polaris`}</a>{` or `}<a parentName="p" {...{
        "href": "https://atlaskit.atlassian.com/packages/core/button"
      }}>{`Atlasian AtlasKit`}</a>{`:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`simple onboarding`}</strong>{` (intro, get started, philosophy, tooling, ...)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`tokens`}</strong>{` (variables)`}</p>
        <ul parentName="li">
          <li parentName="ul">{`token names reflect intention/meaning, not it's visual style (~min 10 in video)`}
            <ul parentName="li">
              <li parentName="ul"><inlineCode parentName="li">{`hoppinTurquoise`}</inlineCode>{` => `}<inlineCode parentName="li">{`brandPrimary`}</inlineCode></li>
              <li parentName="ul"><inlineCode parentName="li">{`red`}</inlineCode>{` => `}<inlineCode parentName="li">{`danger`}</inlineCode></li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`components`}</strong>{` with docs on props, usage, best practices`}</p>
        <ul parentName="li">
          <li parentName="ul">{`When you need a new component, build it locally in the app first, test it, and if it works well, push it upstream into the design-system.`}</li>
          <li parentName="ul">{`Flat Component Hierarchies! `}<a parentName="li" {...{
              "href": "https://varun.ca/flattening-deep-hierarchies-of-compo"
            }}>{`https://varun.ca/flattening-deep-hierarchies-of-components/`}</a></li>
        </ul>
      </li>
    </ul>
    <p>{`Guiding principles for components:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Keep layout separate`}</strong></p>
        <ul parentName="li">
          <li parentName="ul">{`Design components to only care about itself, not the layout. So say a box should always fill it's container with `}<inlineCode parentName="li">{`flex-grow: 1`}</inlineCode></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Respond to context`}</strong></p>
        <ul parentName="li">
          <li parentName="ul">{`e.g. colors not hardcoded, currentColor`}</li>
          <li parentName="ul">{`for us: don't use pink, turqoise, etc. use brandPrimary, brandAccent, etc.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Document Assumptions`}</strong></p>
        <ul parentName="li">
          <li parentName="ul">{`for us: use PropTypes or TypeScript, add inline doc comments explaining props.`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "setup"
    }}>{`Setup`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Independent Repo, later npm module`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`All apps depend on design-system module`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`co-locate component and examples?`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`folder structure like:`}</p>
            <ul parentName="li">
              <li parentName="ul"><inlineCode parentName="li">{`components`}</inlineCode>
                <ul parentName="li">
                  <li parentName="ul"><inlineCode parentName="li">{`Button`}</inlineCode>
                    <ul parentName="li">
                      <li parentName="ul"><inlineCode parentName="li">{`index.js`}</inlineCode>{` - all the exports`}</li>
                      <li parentName="ul"><inlineCode parentName="li">{`Button.js`}</inlineCode>{` - component`}</li>
                      <li parentName="ul"><inlineCode parentName="li">{`Button.md`}</inlineCode>{` or `}<inlineCode parentName="li">{`readme.md`}</inlineCode>{` (examples for design-system)`}</li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li parentName="ul">
            <p parentName="li">{`inline `}<a parentName="p" {...{
                "href": "https://jsdoc.app/"
              }}>{`jsdoc`}</a>{` comments for props, etc`}</p>
          </li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "reading-list"
    }}>{`Reading List`}</h2>
    <p>{`Some interesting inputs came form:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.youtube.com/watch?v=XWetuOvNSk0"
          }}>{`Dominic McPhee (Shopify)- Building a Design System in React (Talk at NordicJS 2018)`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`Both Philisophy and implemention in react (with fallback to CSS & HTML!)`}</li>
          <li parentName="ul">{`Intro to Polaris and it's used `}<a parentName="li" {...{
              "href": "https://polaris.shopify.com/components/get-started"
            }}>{`here`}</a></li>
          <li parentName="ul">{`When you need a new component, build it locally in the app first, test it, and if it works well, push it upstream into the design-system.`}</li>
          <li parentName="ul">{`token names reflect intention/meaning, not it's visual style (~min 10 in video)`}
            <ul parentName="li">
              <li parentName="ul"><inlineCode parentName="li">{`hoppinTurquoise`}</inlineCode>{` => `}<inlineCode parentName="li">{`brandPrimary`}</inlineCode></li>
              <li parentName="ul"><inlineCode parentName="li">{`red`}</inlineCode>{` => `}<inlineCode parentName="li">{`danger`}</inlineCode></li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.youtube.com/watch?v=XR6eM_5pAb0"
          }}>{`Glenn Maddern - The Future of Reusable CSS (Talk at ColdFront16)`}</a></p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`Especially from `}<a parentName="p" {...{
                "href": "https://youtu.be/XR6eM_5pAb0?t=1069"
              }}>{`minute 17`}</a></p>
          </li>
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`Keep layout separate`}</strong></p>
            <ul parentName="li">
              <li parentName="ul">{`Design components to only care about itself, not the layout. So say a box should always fill it's container with `}<inlineCode parentName="li">{`flex-grow: 1`}</inlineCode></li>
            </ul>
          </li>
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`Respond to context`}</strong></p>
            <ul parentName="li">
              <li parentName="ul">{`e.g. colors not hardcoded, currentColor`}</li>
              <li parentName="ul">{`for us: don't use pink, turqoise, etc. use brandPrimary, brandAccent, etc.`}</li>
            </ul>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Document Assumptions`}</p>
            <ul parentName="li">
              <li parentName="ul">{`for us: use PropTypes or TypeScript, add inline doc comments explaining props.`}</li>
            </ul>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Open for modification`}</p>
            <ul parentName="li">
              <li parentName="ul">{`for now, fork into local, test, commit to design-system later?`}</li>
              <li parentName="ul">{`maybe later: `}<a parentName="li" {...{
                  "href": "https://medium.com/@dschnr/better-reusable-react-components-with-the-overrides-pattern-9eca2339f646"
                }}>{`Override Pattern`}</a>{` as also used by `}<a parentName="li" {...{
                  "href": ""
                }}>{`Uber Base Web`}</a>{` and described in `}<a parentName="li" {...{
                  "href": "https://blog.risingstack.com/design-systems-react/"
                }}>{`Gergely Nemeth's article on design systems`}</a></li>
            </ul>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Tokens`}</p>
            <ul parentName="li">
              <li parentName="ul">{`Sort of invented by Jina Ane`}
                <ul parentName="li">
                  <li parentName="ul">{`Lead Designer for Salesforce Lightening Design System`}</li>
                  <li parentName="ul"><a parentName="li" {...{
                      "href": "https://pca.st/Kdev"
                    }}>{`Podcast`}</a></li>
                  <li parentName="ul"><a parentName="li" {...{
                      "href": "https://speakerdeck.com/jina/design-tokens-in-design-systems"
                    }}>{`Slides`}</a></li>
                  <li parentName="ul"><a parentName="li" {...{
                      "href": "https://github.com/salesforce-ux/theo"
                    }}>{`Theo`}</a>{` a library to manage and translate tokens between platforms`}</li>
                </ul>
              </li>
              <li parentName="ul">{`For token naming issues, the guys at `}<a parentName="li" {...{
                  "href": "https://github.com/system-ui/theme-specification"
                }}>{`system-ui`}</a>{` have some good ideas, `}<a parentName="li" {...{
                  "href": "%5Bhttps://github.com/system-ui/theme-specification/issues"
                }}>{`discussions`}</a></li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Thinking by Brent Jackson`}</strong></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://jxnblk.com/blog/iterations-on-a-theme/"
            }}>{`on theming`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://jxnblk.com/blog/patterns-for-style-composition-in-react/"
            }}>{`on styles in react in general`}</a>{` which lead to:`}</li>
        </ul>
      </li>
    </ul>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Styled-System`}</strong></p>
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
                "href": "https://varun.ca/styled-system/"
              }}>{`fantastic article`}</a>{` by Varun Vachhar`}</strong>{` explaining all the thinking behind it and how it ties together lots of the above thinking.`}</li>
          <li parentName="ul">{`Alan B Smith from Sendgrid on `}<a parentName="li" {...{
              "href": "https://medium.com/styled-components/build-better-component-libraries-with-styled-system-4951653d54ee"
            }}>{`Building Better Component Libraries with Styled System`}</a></li>
          <li parentName="ul">{`note: styled-system calls the object holding all the tokens `}<inlineCode parentName="li">{`theme`}</inlineCode>{` other places call it `}<inlineCode parentName="li">{`tokens`}</inlineCode>{` or whatnot.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.youtube.com/watch?v=hrbksRbx2Hk&feature=youtu.be&utm_campaign=Design%2BSystems%2BWeekly&utm_medium=web&utm_source=Design_Systems_Weekly_81"
          }}>{`https://www.youtube.com/watch?v=hrbksRbx2Hk&feature=youtu.be&utm_campaign=Design%2BSystems%2BWeekly&utm_medium=web&utm_source=Design_Systems_Weekly_81`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`Slides: `}<a parentName="li" {...{
              "href": "https://github-ds.now.sh/#3"
            }}>{`https://github-ds.now.sh/#3`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://publication.design.systems/design-systems-are-for-people-a484620b6988"
          }}>{`https://publication.design.systems/design-systems-are-for-people-a484620b6988`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.designbetter.co/books"
          }}>{`https://www.designbetter.co/books`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://jxnblk.com/blog/iterations-on-a-theme/"
          }}>{`https://jxnblk.com/blog/iterations-on-a-theme/`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://medium.com/styled-components/component-folder-pattern-ee42df37ec68"
          }}>{`https://medium.com/styled-components/component-folder-pattern-ee42df37ec68`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Flat Component Hierarchies! `}<a parentName="p" {...{
            "href": "https://varun.ca/flattening-deep-hierarchies-of-compo"
          }}>{`https://varun.ca/flattening-deep-hierarchies-of-components/`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Chroma.js color helper functions.`}<a parentName="p" {...{
            "href": "https://www.vis4.net/blog/2013/09/mastering-multi-hued-color-scales/"
          }}>{`https://www.vis4.net/blog/2013/09/mastering-multi-hued-color-scales/`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://daneden.me/2018/01/05/subatomic-design-systems/"
          }}>{`https://daneden.me/2018/01/05/subatomic-design-systems/`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Polaris Design System (Shopify) `}<a parentName="p" {...{
            "href": "https://www.youtube.com/watch?v=XWetuOvNSk0"
          }}>{`https://www.youtube.com/watch?v=XWetuOvNSk0`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.freecodecamp.org/news/introducing-the-single-element-pattern-dfbd2c295c5d/"
          }}>{`https://www.freecodecamp.org/news/introducing-the-single-element-pattern-dfbd2c295c5d/`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.programmingdesignsystems.com/"
          }}>{`https://www.programmingdesignsystems.com`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://medium.com/codyhouse/create-your-design-system-part-1-typography-7c630d9092bd"
          }}>{`https://medium.com/codyhouse/create-your-design-system-part-1-typography-7c630d9092bd`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.designsystems.com/"
          }}>{`https://www.designsystems.com/`}</a></p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      